'use client';

import React, { useEffect, useState } from 'react';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { AppProvider } from 'src/context/app';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { getAnonymousUserId, getUserUuid } from '@get-mosh/msh-shared';
import { growthbook } from 'src/analytics/Growthbook';
import Brands from './constants/Brands';
import DatadogInit from './analytics/Datadog';
import { datadogRum } from '@datadog/browser-rum';

type ProvidersProps = {
    children: React.ReactNode;
};

function Providers({ children }: ProvidersProps) {
    const [client] = useState(new QueryClient());

    useEffect(() => {
        const userId = getUserUuid() || getAnonymousUserId();
        growthbook.setAttributes({ id: userId, brand: Brands.MOSHY });
        growthbook.loadFeatures();

        if (userId) {
            datadogRum.setUser({
                id: userId,
                brand: Brands.MOSHY,
            });
        }
    }, []);

    return (
        <AppProvider>
            <GrowthBookProvider growthbook={growthbook}>
                <QueryClientProvider client={client}>
                    <DatadogInit />
                    {children}
                </QueryClientProvider>
            </GrowthBookProvider>
        </AppProvider>
    );
}

export default Providers;
